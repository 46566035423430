








































































import Vue, { PropType } from "vue";
import { WebinarAssociation } from "@/clients/clients";
import { mapState } from "vuex";

export default Vue.extend({
  name: "WebinarAssociationFields",
  props: {
    existing: Array as PropType<WebinarAssociation[]>,
  },
  data: (): {
    form: {
      associations: WebinarAssociation[];
      newAssociations: {
        name: string;
        logo: File | null;
      }[];
    };
  } => ({
    form: {
      associations: [],
      newAssociations: [],
    },
  }),
  computed: {
    ...mapState(["assetsBaseUrl"]),
    rules: (): { (value: File): boolean }[] => [
      (value: File): boolean => !value || value.size < 2000000,
      (value: File): boolean =>
        !value || /image\/jpeg|image\/jpg|image\/png/.test(value.type),
    ],
  },
  created() {
    if (this.existing && this.existing.length) {
      this.form.associations = [...this.existing];
    }
  },
  methods: {
    deleteAssociation(index: number) {
      this.form.associations.splice(index, 1);
      this.$emit("input", this.form);
    },
    deleteNewAssociation(index: number) {
      this.form.newAssociations.splice(index, 1);
      this.$emit("input", this.form);
    },
    addItem() {
      this.form.newAssociations.push({
        name: "",
        logo: null,
      });
    },
  },
});

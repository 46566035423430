








































































import Vue, { PropType } from "vue";
import { BrandPromotion, WebinarAssociation } from "@/clients/clients";
import { mapState } from "vuex";

export default Vue.extend({
  name: "WebinarPromotionFields",
  props: {
    existing: Array as PropType<BrandPromotion[]>,
  },
  data: (): {
    form: {
      brandPromotions: WebinarAssociation[];
      newBrandPromotions: {
        name: string;
        logo: File | null;
      }[];
    };
  } => ({
    form: {
      brandPromotions: [],
      newBrandPromotions: [],
    },
  }),
  computed: {
    ...mapState(["assetsBaseUrl"]),
    rules: (): { (value: File): boolean }[] => [
      (value: File): boolean => !value || value.size < 2000000,
      (value: File): boolean =>
        !value || /image\/jpeg|image\/jpg|image\/png/.test(value.type),
    ],
  },
  created() {
    if (this.existing && this.existing.length) {
      this.form.brandPromotions = [...this.existing];
    }
  },
  methods: {
    deleteBrand(index: number) {
      this.form.brandPromotions.splice(index, 1);
      this.$emit("input", this.form);
    },
    deleteNewBrand(index: number) {
      this.form.newBrandPromotions.splice(index, 1);
      this.$emit("input", this.form);
    },
    addItem() {
      this.form.newBrandPromotions.push({
        name: "",
        logo: null,
      });
    },
  },
});
